import useScrollToID from "@/hooks/useScrollToID";
import { DesignSystemComponent, FAQT } from "@/types/design-system-types";
import ComponentDebugger from "@/utils/system/component-debugger";
import Page from "@/utils/system/page";
import { useRouter } from "next/router";
import Accordion from "../core/molecules/accordion";
import { useEffect, useState } from "react";
import { scrollToID } from "@/utils/helpers";

const FAQ = ({
  data,
  question = data?.fields?.question || '',
  answer = data?.fields?.answer || '',
  theme,

  ...props
}: DesignSystemComponent<FAQT>) => {
  theme = data?.fields.theme ?? 'white-100';

  const router = useRouter();

  const accordionQuestion = question.toLocaleLowerCase().replaceAll(' ', '-');

  const [shouldBeOpen, setShouldBeOpen] = useState(false);

  const themeClass = Page.getThemeClass(theme);

  useEffect(() => {
    const handleHashChange = () => {
      const hashQuestion = window.location.hash.replace('#', '');
      const urlQuestion = router.query.q as string;

      if (hashQuestion === accordionQuestion || urlQuestion === accordionQuestion) {
        setShouldBeOpen(true);
        scrollToID(accordionQuestion, 265);
      } else {
        setShouldBeOpen(false);
      }
    };

    // Check the initial hash and query parameters
    handleHashChange();

    // Add event listener for hash change
    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [router.query.q, accordionQuestion, router.asPath]);

  return (
    <Accordion
      openByDefault={shouldBeOpen}
      id={accordionQuestion}
      debug={<ComponentDebugger data={data} />}
      data={data as any}
      title={question}
      content={answer}
      isFAQ={true}
      theme={theme}
      className="n-accordion"
      {...props}
    />
  );
};

export default FAQ;